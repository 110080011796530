/**
 * API Worker
 *
 * This file contains all the functions that make API requests to the server.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// Importing the axios library, which is used to make the requests
import axios from "axios";

/**
 * It makes a GET request to the url, with the action and params as query parameters
 * @param {string} url - The url to send the request to
 * @param {string} action - The action to be performed on the server
 * @param {object} params - The params to be sent to the server
 * @returns Promise - The response data from the server
 */
const get = async (url, action, params) => {
  try {
    const response = await axios.get(url, {
      params: { action: action, ...params },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return { ...error.response.data, errorCode: error.response.status };
  }
};

/**
 * It sends a POST request to the server, and returns the response data
 * @param {string} url - The url to send the request to
 * @param {string} action - The action to be performed on the server
 * @param {object} body - The body of the request
 * @returns Promise - The response data from the server
 */
const post = async (url, action, body) => {
  try {
    const response = await axios.post(
      url,
      { action: action, ...body },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { ...error.response.data, statusCode: error.response.status };
  }
};

/**
 * It sends a POST request to the server, and returns the response data
 * @param {string} url
 * @param {object} body - the body should be a FormData object
 * @returns Promise - The response data from the server
 */
const postFormData = async (url, body) => {
  try {
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return { ...error.response.data, statusCode: error.response.status };
  }
};

/**
 * It sends a PUT request to the server, and returns the response data
 * @param {string} url - The url to send the request to
 * @param {string} action - The action to be performed on the server
 * @param {object} body - The body of the request
 * @returns Promise - The response data from the server
 */
const put = async (url, action, body) => {
  try {
    const response = await axios.put(
      url,
      { action: action, ...body },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    return { ...error.response.data, statusCode: error.response.status };
  }
};

/**
 * It sends a DELETE request to the server, and returns the response data
 * @param {string} url - The url to send the request to
 * @param {string} action - The action to be performed on the server
 * @param {object} body - The body of the request
 * @returns Promise - The response data from the server
 */
const del = async (url, action, body) => {
  try {
    const response = await axios.delete(url, {
      data: { action: action, ...body },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return { ...error.response.data, statusCode: error.response.status };
  }
};

export { get, post, postFormData, put, del };
