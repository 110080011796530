/**
 * Navbar component
 *
 * This file contains the Navbar component,
 * which is used to display the navbar links.
 *
 * React Component  
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// router
import { useNavigate, useLocation } from "react-router-dom";

// PrimeReact components
import { Button } from "primereact/button";

// custom css
import "../css/navbar.css";

// data
import { navbarData } from "./navbarData.js";


const Navbar = (props) => {
  const { onProfileClick } = props;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="row navbarRow">
      <div className="col-6">
        <h1>Théré'Cam</h1>
      </div>
      <div className="col-6 text-end">
        {location.pathname === "/therecam" ? (
          <>
          <Button
            label="Accueil"
            icon="pi pi-home"
            className="p-button-outlined p-button-sm navbarButton"
            iconPos="right"
            onClick={() => navigate("/")}  
          />
          <Button
            label=""
            icon="pi pi-user"
            className="p-button-outlined p-button-sm navbarButton"
            iconPos="right"
            onClick={onProfileClick}
          />
            </>
        ) : (
          navbarData.map((navbarItem) => {
            if (navbarItem.path === location.pathname) return;
            return (
              <Button
                key={navbarItem.path}
                label={navbarItem.label}
                icon={navbarItem.icon}
                className="p-button-outlined p-button-sm navbarButton"
                iconPos="right"
                onClick={() => navigate(navbarItem.path)}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
export default Navbar;
