/**
 * Composition component 
 *
 * This file contains the CompositionList component,
 * which is used to display all the composition.
 *
 * React Component  
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// react hooks
import { useState } from "react";

// PrimeReact components
import { Paginator } from "primereact/paginator";

// custom components
import Composition from "./Composition";

// custom css
import "../css/compositions.css";

const CompositionList = ({
  compositions,
  isEditable,
  onPlay,
  onStop,
  onEdit,
  onDelete,
  itemsPerPage = 8,
}) => {
  // states
  const [page, setPage] = useState(0);

  const [compositionBeingPlayed, setCompositionBeingPlayed] = useState(null);
  const [compositionBeingEdited, setCompositionBeingEdited] = useState(null);


  // event handlers
  const handleOnPlay = (composition, playing) => {
    onPlay(composition, playing);
    if (!playing) {
      setCompositionBeingPlayed(null);
      return;
    }
    setCompositionBeingPlayed(composition.pk_composition);
  };

  const handleOnStop = (composition) => {
    onStop(composition);
    setCompositionBeingPlayed(null);
  }

  const handleOnEdit = (composition, editing) => {
    onEdit(composition, editing);
    if (!editing) {
      setCompositionBeingEdited(null);
      return;
    }
    setCompositionBeingEdited(composition.pk_composition);
  };

  return (
    <>
    {compositions && (<>
      <Composition key="compo-title" isTitle isEditable={isEditable}/>
      {compositions && compositions.map((composition, i) => {
        // only display compositions that are in the actual paginator range
        if(i < page || i >= page + itemsPerPage) {
          return null;
        }
        return (
          <Composition
            key={composition.pk_composition}
            isEditable={isEditable}
            composition={composition}
            isBeingPlayed={
              compositionBeingPlayed === composition.pk_composition
            }
            onPlay={handleOnPlay}
            onStop={handleOnStop}
            onEdit={handleOnEdit}
            isBeingEdited={
              compositionBeingEdited === composition.pk_composition
            }
            onDelete={onDelete}
          />
        );
      })}
      <Paginator
        className="my-4"
        totalRecords={compositions.length}
        rows={itemsPerPage}
        first={page}
        onPageChange={(e) => setPage(e.first)}
      />
    </>)}
    </>
  );
};

export default CompositionList;
