/**
 * User request worker
 *
 * This file contains the functions that make API
 * requests to the user API endpoint.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// ApiWrk functions
import { put } from "../wrk/apiWrk";

// Api endpoints
import { ENDPOINTS } from "../constant/constants";

/**
 * Update the user firstname and lastname
 * @param {string} firstname 
 * @param {string} lastname 
 * @returns Promise
 */
const updateUserName = async(firstname, lastname) => {
  return await put(ENDPOINTS.USER, "updateUserName", { firstname, lastname});
};

/**
 * Update the user current password
 * @param {string} newPassword 
 * @param {string} currentPassword 
 * @returns Promise
 */
const updateUserPassword = async(newPassword, currentPassword) => {
  return await put(ENDPOINTS.USER, "updateUserPassword", {
    newPassword,
    currentPassword
  });
};

export { updateUserName, updateUserPassword};