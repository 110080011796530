/**
 * LocalStorage Wrk
 *
 * This file contains all the functions that use the tone.js library to generate/record sound
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// import the tone.js library
import * as Tone from "tone";

// import the constants
import { FREQUENCY, VOLUME, HAND_RELATIVE_POSITION } from "../constant/constants";

//create the oscillator
const osc = new Tone.Oscillator(440, "sine");

/* Create a filter to the oscillator  
const crusher = new Tone.BitCrusher(4).toDestination();
osc.connect(crusher); */

// create the recorder and connect it to oscillator
const recorder = new Tone.Recorder();
osc.connect(recorder);

// create the audio player
const player = new Tone.Player();
player.toDestination();


const gainNode = new Tone.Gain(0);
gainNode.toDestination();
osc.connect(gainNode);

/**
 * Start the oscillator and record the sound
 * @returns Promise
 */
const startOscillator = async () => {
  osc.frequency.value = FREQUENCY.MIN;
  osc.volume.value = VOLUME.MIN;
  osc.start();
  try {
    await recorder.start();
    return { status: true, message: "" };
  } catch (error) {
    if (error.message.includes("Recorder is already started")) {
      return { status: true, message: "" };
    }
    return { status: false, message: "ERROR_WHILE_STARTING_RECORDING" };
  }
};

/**
 * Stop the oscillator and return the recorded sound
 * @returns Promise
 */
const stopOscillator = async () => {
  osc.stop();
  try {
    return await recorder.stop();
  } catch (error) {
    if (error.message.includes("Recorder is not started")) {
      return { status: true, message: "" };
    }
    return { status: false, message: "ERROR_WHILE_STOPPING_RECORDING" };
  }
};

/**
 * Change the current frequency and volume of the oscillator
 * @param {int} frequency
 * @param {int} volume
 * @returns
 */
const changeOscillatorNote = (frequency, volume) => {
  if (!frequency || !volume) {
    return;
  }
  // scale the frequency and volume to the constants min an max range
  const scaledFrequency = _scaleHandsPositionsToFrequency(frequency);
  const scaledVolume = _scaleHandsPositionsToVolume(volume);

  // change the values of the oscillator
  osc.frequency.value = scaledFrequency;

  // change the volume of the oscillator with a delay of 0.5s
  gainNode.gain.rampTo(scaledVolume, 0.5);
  osc.volume.value = scaledVolume;

  return { frequency: scaledFrequency, volume: scaledVolume };
};

/**
 * Play the composition audio
 * @param {string} audio - the audio file encoded in base64
 * @param {boolean} play - true if the audio should be played, f
 * @param {boolean} firstPlay - true if the audio is the first time played
 * @returns Promise
 */
const playAudio = async (audio, play, firstPlay) => {
  if (!play) {
    Tone.Transport.pause();
    return { status: true, message: "" };
  }
  try {
    // convert the base64 to a blob
    const base64Response = await fetch(
      `data:audio/webm;codecs=opus;base64,${audio}`
    );
    const blob = await base64Response.blob();
    // load the blob into the player
    await player.load(URL.createObjectURL(blob));
    // start the player
    if(firstPlay){
      player.sync().start(0);
    }
    Tone.Transport.start();

    return { status: true, message: "" };
  } catch (error) {
    return { status: false, message: "ERROR_WHILE_PLAYING_AUDIO" };
  }
};

/**
 * Stop the currently playing audio
 */
const stopAudio = () => {
  Tone.Transport.stop();
};

/**
 * Scale the relative position of the hands to a frequency
 * @param {object} relativePosition 
 * @returns 
 */
const _scaleHandsPositionsToFrequency = (relativePosition) => {
  const difference = FREQUENCY.MAX - FREQUENCY.MIN;
  const scaledFrequency = relativePosition / HAND_RELATIVE_POSITION.MAX;
  return FREQUENCY.MIN + parseInt(scaledFrequency * difference);
};

/**
 * Scale the relative position of the hands to a volume between 0 and 1
 * @param {object} relativePosition 
 * @returns 
 */
const _scaleHandsPositionsToVolume = (relativePosition) => {
  const difference = VOLUME.MAX - VOLUME.MIN;
  const scaledVolume = relativePosition / HAND_RELATIVE_POSITION.MAX;
  // round the volume to 2 decimals
  return Math.round((VOLUME.MIN + scaledVolume * difference)*100)/100;
};

//scale 
export {
  startOscillator,
  stopOscillator,
  changeOscillatorNote,
  playAudio,
  stopAudio,
};
