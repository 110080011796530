/**
 * LocalStorage Wrk
 *
 * This file contains all the functions that use the localStorage to interact with the user object.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

/**
 * Save user in local storage
 * @param {object} user
 */
const saveUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

/**
 * Clear user from local storage
 */
const clearUser = () => {
  localStorage.removeItem("user");
};

/**
 * Get user from local storage
 * @returns {object} user
 */
const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export { saveUser, clearUser, getUser };
