/**
 * Composition component 
 *
 * This file contains the Composition component, 
 * which is used to display a composition in the compositions list. 
 *
 * React Component  
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// react hooks
import { useState, useEffect } from "react";

// PrimeReact components
import { Button } from "primereact/button";

const Composition = ({
  isTitle,
  isEditable,
  composition,
  onPlay,
  onStop,
  onEdit,
  onDelete,
  isBeingPlayed,
  isBeingEdited,
}) => {
  // states
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  // event handlers
  const onCompositionPlay = () => {
    onPlay(composition, !isPlaying);
    setIsPlaying(!isPlaying);
  };

  const onCompositionStop = () => {
    onStop(composition);
    setIsPlaying(false);
  };

  const onCompositionEdit = () => {
    onEdit(composition, !isEditing);
    setIsEditing(!isEditing);
  };

  const onCompositionDelete = () => {
    onDelete(composition);
  };

  // useEffect
  useEffect(() => {
    setIsPlaying(isBeingPlayed);
    setIsEditing(isBeingEdited);
  });

  return (
    <>
      {/* Only displayed if it's a title */}
      {isTitle ? (
        <div className="row titleRow">
          <div className="col col-4 px-4">
            <h5>Titre</h5>
            <span className="separator"></span>
          </div>
          <div className="col col-2 px-4">
            <h5>Vues</h5>
            <span className="separator"></span>
          </div>
          <div className="col col-3 px-4">
            <h5>Contrôles</h5>
            <span className="separator"></span>
          </div>
          {isEditable && (
            <div className="col col-3 px-4">
              <h5>Modifier</h5>
              <span className="separator"></span>
            </div>
          )}
        </div>
      ) : (
        <div className="row compositionRow">
          <div className="col col-4 py-1">
            <div className="compositionInfo h-100">
              <span>{composition.title}</span>
            </div>
          </div>
          <div className="col col-2 py-1">
            <div className="compositionInfo h-100">
              <span>{composition.views}</span>
            </div>
          </div>
          <div className="col col-3 py-1">
            <div className="compositionInfo h-100">
              <Button
                icon={`pi ${isPlaying ? "pi-pause" : "pi-play"}`}
                className={`p-button-sm p-button-outlined mx-2 ${
                  isPlaying ? "compositionPlaying" : ""
                }`}
                onClick={onCompositionPlay}
              />
              <Button
                icon="pi pi-stop"
                className="p-button-sm p-button-outlined"
                onClick={onCompositionStop}
              />
            </div>
          </div>
          {/* Only displayed if the composition is editable */}
          {isEditable && (
            <div className="col col-3 py-1">
              <div className="compositionInfo  h-100">
                <Button
                  icon="pi pi-pencil"
                  className={`p-button-sm ${
                    isEditing ? "" : "p-button-outlined"
                  } mx-2`}
                  onClick={onCompositionEdit}
                />
                <Button
                  icon="pi pi-trash"
                  className="p-button-sm p-button-danger p-button-outlined"
                  onClick={onCompositionDelete}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Composition;
