/**
 * Composition controller
 *
 * This file contains all functions that will be used by the Component.
 * Contains the composition API, the hantrack/tone worker functions.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// import all functions from the compositionApi
import * as compositionApi from "../api/compositionApi";
import * as handtrackWrk from "../wrk/handtrackWrk";
import * as toneWrk from "../wrk/toneWrk";
/**
 * Get all the compositions
 * @returns Promise
 */
const getAllCompositions = async () => {
  return await compositionApi.getAllCompositions();
};

/**
 * Get all the compositions belonging to the current user
 * @returns Promise
 */
const getUserCompositions = async () => {
  return await compositionApi.getUserCompositions();
};

/**
 * Get the composition audio file with the given id
 * @param {int} pkComposition
 * @returns Promise
 */
const getCompositionAudio = async (pkComposition) => {
  return await compositionApi.getCompositionAudio(pkComposition);
};

/**
 * Create a new composition with the given title
 */
const insertComposition = async (audio, sample_interval, samples, title) => {
  return await compositionApi.insertComposition(
    audio,
    sample_interval,
    samples,
    title
  );
};

/**
 * Update the composition title with the given id
 * @param {int} pkComposition
 * @param {string} title
 * @returns Promise
 */
const updateComposition = async (pkComposition, title) => {
  return await compositionApi.updateComposition(pkComposition, title);
};

/**
 * Delete the composition with the given id
 * @param {int} pkComposition
 * @returns Promise
 */
const deleteComposition = async (pkComposition) => {
  return await compositionApi.deleteComposition(pkComposition);
};

/**
 * Load the handtrack model
 * @returns Promise
 */
const loadModel = async () => {
  return await handtrackWrk.loadModel();
};

/**
 * Start the video output stream
 * @param {element} video
 * @returns  Promise
 */
const startVideo = async (video) => {
  return await handtrackWrk.startVideo(video);
};

/**
 * Stop the video output stream
 * @param {element} video
 * @returns  Promise
 */
const stopVideo = async () => {
  return await handtrackWrk.stopVideo();
};

/**
 * Track the hands positions
 * @param {object} model
 * @param {element} canvas
 * @param {element} context
 * @param {element} video
 * @returns
 */
const trackHands = async (model, canvas, context, video) => {
  return await handtrackWrk.trackHands(model, canvas, context, video);
};


/**
 * Start the oscillator
 * @returns Promise
 */
const startOscillator = async () => {
  return toneWrk.startOscillator();
};

/**
 * Stop the oscillator
 * @returns Promise
 */
const stopOscillator = async ()  => {
  return toneWrk.stopOscillator();
};

/**
 * Change the oscillator frequency
 * @param {int} frequency 
 * @param {int} volume 
 * @returns {object}
 */
const changeOscillatorNote = (frequency, volume) => {
    return toneWrk.changeOscillatorNote(frequency, volume);
};

/**
 * Play the composition audio
 * @param {string} audio - the audio file encoded in base64
 * @param {boolean} play - true if the audio should be played, false if it should be paused
 * @param {boolean} firstPlay - true if the audio is the first time played
 * @returns Promise
 */
const playAudio = async (audio, play, firstPlay) => {
    return toneWrk.playAudio(audio, play, firstPlay);
}

/**
 * Pause the currently playing audio
 */
const stopAudio = () => {
    toneWrk.stopAudio();
}


export {
  getAllCompositions,
  getUserCompositions,
  getCompositionAudio,
  insertComposition,
  updateComposition,
  deleteComposition,
  loadModel,
  startVideo,
  stopVideo,
  trackHands,
  startOscillator,
  stopOscillator,
  changeOscillatorNote,
  playAudio,
  stopAudio,
};
