/**
 * Composition request worker
 *
 * This file contains the functions that make API
 * requests to the composition API endpoint.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// ApiWrk functions
import { get, post, postFormData, put, del } from "../wrk/apiWrk";

// Api endpoints
import { ENDPOINTS } from "../constant/constants";


/**
 * Get all the compositions
 * @returns Promise
 */
const getAllCompositions = async () => {
  return await get(ENDPOINTS.COMPOSITION, "getAllCompositions", {});
};

/**
 * Get all the compositions belonging to the current user
 * @returns Promise
 */
const getUserCompositions = async () => {
  return await get(ENDPOINTS.COMPOSITION, "getUserCompositions", {});
};

/**
 * Get the composition audio file with the given id
 * @param {int} pkComposition
 * @returns Promise
 */
const getCompositionAudio = async (pkComposition) => {
  return await get(ENDPOINTS.COMPOSITION, "getCompositionAudio", {
    pk_composition: pkComposition,
  });
};

/**
 * Create a new composition with the given title
 * @param {blob} title
 * @param {int} sample_interval
 * @param {JSON} samples
 * @param {string} title
 * @returns Promise
 * 
 */
const insertComposition = async (audio, sample_interval, samples, title) => {
  const formdata = new FormData();
  formdata.append("action", "insertComposition");
  formdata.append("audio", audio);
  formdata.append("sample_interval", sample_interval);
  formdata.append("samples", JSON.stringify(samples));
  formdata.append("title", title);

  return await postFormData(ENDPOINTS.COMPOSITION, formdata);
};

/**
 * Update the composition title with the given id
 * @param {int} pkComposition 
 * @param {string} title 
 * @returns Promise
 */
const updateComposition = async (pkComposition, title) => {
  return await put(ENDPOINTS.COMPOSITION, "updateComposition", {
    pk_composition: pkComposition,
    title,
  });
};

/**
 * Delete the composition with the given id
 * @param {int} pkComposition 
 * @returns Promise
 */
const deleteComposition = async (pkComposition) => {
  return await del(ENDPOINTS.COMPOSITION, "deleteComposition", {
    pk_composition: pkComposition,
  });
};

export {
  getAllCompositions,
  getUserCompositions,
  getCompositionAudio,
  insertComposition,
  updateComposition,
  deleteComposition,
};
