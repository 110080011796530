/**
 * Router
 *
 * This file contains all the route logic used to navigate between pages.
 *
 * React component
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

//Import the router
import { useEffect } from "react";
import { BrowserRouter as RouterDom, Routes, Route } from "react-router-dom";

//Import the pages
import Home from "../pages/Home";
import Login from "../pages/Login";
import Therecam from "../pages/Therecam";

const Router = () => {
  return (
    <div className="container">
      <RouterDom key="router" basename="/frontend">
        <Routes>
          <Route key="/" path="/" exact element={<Home />} />
          <Route
            key="/login"
            path="/login"
            exact
            element={<Login page="login" />}
          />
          <Route
            key="/register"
            path="/register"
            exact
            element={<Login page="register" />}
          />
          <Route
            key="/therecam"
            path="/therecam"
            exact
            element={<Therecam />}
          />
        </Routes>
      </RouterDom>
    </div>
  );
};

export default Router;
