/**
 * User controller
 *
 * This file contains all functions that will be used by the Component.
 * Contains the user API functions.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// import all functions from the userApi
import * as userApi from "../api/userApi";

// import functions to interact with localStorage
import { getUser, saveUser } from "../wrk/localStorageWrk";

/**
 * Get the current user firstname and lastname from the localStorage
 * @returns {firstname : string, lastname : string}
 */
const getUserName = () => {
  const user = getUser();
  return { firstname: user ? user.firstname : "", lastname: user ? user.lastname : "" };
};

/**
 * Update the user firstname and lastname
 * @param {string} firstname
 * @param {string} lastname
 * @returns Promise
 */
const updateUserName = async (firstname, lastname) => {
  const result =  await userApi.updateUserName(firstname, lastname);
  if(result.status === "success") {
    saveUser(result.data);
  }
  return result;
};

/**
 * Update the user current password
 * @param {string} newPassword
 * @param {string} currentPassword
 * @returns Promise
 */
const updateUserPassword = async (newPassword, oldPassword) => {
  return await userApi.updateUserPassword(newPassword, oldPassword);
};

export { updateUserName, updateUserPassword, getUserName };
