/**
 * Constants file
 *
 * This file contains all the constants used in the application.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// Server endpoints root url
const BASE_URL = "https://therecam.emf-infopro-tpi.ch/backend/ctrl/";

// All the server endpoints
const ENDPOINTS = {
  LOGIN: BASE_URL + "login.php",
  USER: BASE_URL + "user.php",
  COMPOSITION: BASE_URL + "composition.php",
};

// all the possible message that can displayed , a lot of them are returned by the server
const MESSAGES = {
  EMAIL_ALREADY_IN_USE: "Adresse mail déjà utilisée",
  INVALID_EMAIL: "Adresse mail invalide",
  INVALID_FIELDS : "Champs invalides ",
  MISSING_PARAMS: "Paramètres manquants",
  INVALID_PARAMS: "Paramètres invalides",
  UNKNOWN_ACTION: "Action inconnue",
  UNKNOWN_ERROR: "Erreur inconnue",
  FORBIDDEN: "Accès interdit",
  MISSING_FILES: "Fichiers manquants",
  INVALID_JSON: "JSON invalide",
  INVALID_CREDENTIALS: "Identifiants incorrects",
  SUCCESSFULLY_CREATED_ACCOUNT: "Compte créé avec succès",
  SUCCESSFULLY_INSERTED: "Insertion réussie",
  SUCCESSFULLY_UPDATED: "Mise à jour réussie",
  SUCCESSFULLY_DELETED: "Suppression réussie",
  SUCCESSFULLY_LOGOUT_OUT: "Déconnexion réussie",
  NO_WEBCAM_FOUND: "Aucune webcam trouvée",
  ERROR_WHILE_LOADING_MODEL: "Erreur lors du chargement du modèle",
  ERROR_WHILE_STOPPING_VIDEO: "Erreur lors de l'arrêt de la vidéo",
  ERROR_WHILE_STARTING_RECORDING: "Erreur lors du démarrage de l'enregistrement",
  ERROR_WHILE_STOPPING_RECORDING: "Erreur lors de l'arrêt de l'enregistrement",
  ERROR_WHILE_PLAYING_AUDIO: "Erreur lors de la lecture de l'audio",
};

// regex expressions used to validate the user input
const REGEX = {
  EMAIL: /^[a-zA-Z0-9._-]{2,64}\@[a-zA-Z0-9._-]{2,190}\.[a-z]{2,4}$/,
  PASSWORD: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
  NAME: /^[a-zA-Z\s]{1,64}$/,
  COMPOSITION_TITLE: /^[a-zA-Z0-9\s]{1,32}$/,
};


// the hand min an max relative position
const HAND_RELATIVE_POSITION = {
  MIN: 0,
  MAX: 1000,
}
// all the classes that correspond to a hand gesture
const HAND_CLASSES = [1, 2, 3, 4, 6, 7];

// the min and max frequency to generate
const FREQUENCY = {
  MIN : 300, 
  MAX : 3000,
}

// the min and max volume to output
const VOLUME = {
  MIN : 0,
  MAX : 1,
}

// sample interval in milliseconds
const SAMPLE_INTERVAL = 200;



export { ENDPOINTS, REGEX, MESSAGES, HAND_RELATIVE_POSITION, HAND_CLASSES, FREQUENCY, VOLUME, SAMPLE_INTERVAL };
