/**
 * Home page
 *
 * This file contains all the state logic and display logic for the Home page.
 * Displays all the compositions.
 *
 * React component
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// react hooks
import { useState, useEffect, useRef } from "react";

import { Toast } from "primereact/toast";

// custom components
import Navbar from "../components/Navbar";
import CompositionsList from "../components/CompositionsList";

// custom css
import "../css/home.css";

// ctrl functions
import {
  getAllCompositions,
  getCompositionAudio,
  playAudio,
  stopAudio,
} from "../ctrl/compositionCtrl";

// constants
import { MESSAGES } from "../constant/constants";

const Home = () => {
  // states
  const [compositions, setCompositions] = useState([]);
  const compositionCurrentlyPlaying = useRef({
    pk_composition: null,
    audio: null,
    numberOfPlays: 0,
  }); // Store the composition currently playing

  // refs
  const toastRef = useRef(null);

  // event handlers

  // play the given composition
  const onCompositionPlay = async (composition, isPlaying) => {
    if (!isPlaying) {
      // stop the currently playing audio, no need to give the back the audio file
      playAudio("", false, false);
      return;
    }

    // get the audio file encoded in base64
    if (
      compositionCurrentlyPlaying.current.pk_composition !==
      composition.pk_composition
    ) {
      const requestResult = await getCompositionAudio(
        composition.pk_composition
      );
      // display an error message if the request failed
      if (requestResult.status === "error") {
        displayMesssage("error", requestResult.message);
        return;
      }

      compositionCurrentlyPlaying.current = {
        pk_composition: composition.pk_composition,
        audio: requestResult.data,
        numberOfPlays: 0,
      };
    }

    compositionCurrentlyPlaying.current.numberOfPlays++;

    // play the audio file
    const audioPlayingStatus = await playAudio(
      compositionCurrentlyPlaying.current.audio,
      true,
      compositionCurrentlyPlaying.current.numberOfPlays === 1
    );

    if (!audioPlayingStatus.status) {
      displayMesssage("error", audioPlayingStatus.message);
    }
  };

  // stop playing the given composition, the compositon is returned by the event but not used
  const onCompositionStop = (composition) => {
    compositionCurrentlyPlaying.current.numberOfPlays = 0;
    stopAudio();
  };

  // load all compositions
  const loadAllCompositions = async () => {
    const requestResult = await getAllCompositions();
    if (requestResult.status === "error") {
      displayMesssage("error", requestResult.message);
      return;
    }
    setCompositions(requestResult.data);
  };

  /**
   * Display a message in the toast component, the message should be a constant from the MESSAGES constant
   * @param {string} type
   * @param {string} message - the message is a key of the MESSAGES constant
   */
  const displayMesssage = (type, message) => {
    toastRef.current.show({
      severity: type,
      summary: MESSAGES[message],
    });
  };

  // load all the compositions after the first render
  useEffect(() => {
    loadAllCompositions();
  }, []);

  return (
    <>
      <Navbar />
      <div className="homeContainer h-100">
        <h2>Home</h2>
        <CompositionsList
          compositions={compositions}
          onPlay={onCompositionPlay}
          onStop={onCompositionStop}
        />
      </div>
      <Toast ref={toastRef} position="bottom-right" />
    </>
  );
};

export default Home;
