/**
 * Login controller
 *
 * This file contains all functions that will be used by the Component.
 * Contains the login API functions, and interact with the local storage.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// import all functions from the loginApi
import * as loginApi from "../api/loginApi";

// import functions to interact with localStorage
import { saveUser, clearUser } from "../wrk/localStorageWrk";

/**
 * Create a new account using the given information
 * @param {string} email 
 * @param {string} password
 * @param {string} firstname
 * @param {string} lastname 
 * @returns Promise
 */
const register = async (email, password, firstname, lastname) => {
  return await loginApi.register(email, password, firstname, lastname);
};

/**
 * Log the user in with the given credentials
 * Save the user in the localStorage if the login is successful
 * Otherwise, clear the user from the localStorage
 * @param {string} email 
 * @param {string} password 
 * @returns Promise
 */
const login = async (email, password) => {
  const result = await loginApi.login(email, password);
  clearUser();
  if(result.status === "success") {
    saveUser(result.data);
  }
  return result;
};

/**
 * Check if the user is logged in
 * Clear the user from the localStorage if the user is not logged in
 * @returns Promise
 */
const isLogged = async () => {
  const result = await loginApi.isLogged();
  if(result.status === "error") {
    clearUser();
  }
  return result;
}

/**
 * Log the current user out and clear the user from the localStorage
 * @returns Promise
 */
const logout = async () => {
  const result = await loginApi.logout();
  if(result.status === "success") {
    clearUser();
  }
  return result;
}

export { register, login, isLogged, logout };
