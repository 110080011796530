/**
 * Home page
 *
 * This file contains all the state logic and display logic for the Login page.
 * Display the login form and the register form.
 *
 * React component
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// react
import { useState, useRef, useEffect } from "react";

// router utils
import { useNavigate } from "react-router-dom";

// PrimeReact components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";

// custom components
import Navbar from "../components/Navbar";

// custom css
import "../css/login.css";

// custom functions
import { login, register, isLogged } from "../ctrl/loginCtrl";
import { checkRegex } from "../utils/regexUtils";

// constants
import { MESSAGES } from "../constant/constants";

const Login = (props) => {
  const { page } = props;

  const navigate = useNavigate();
  const toastRef = useRef(null);

  /* A state that is used to store the values of the inputs. */
  const [fields, setFields] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  /* A state that is used to check if the input are valids or not. */
  const [fieldsValid, setFieldsValid] = useState({
    firstname: true,
    lastname: true,
    email: true,
    password: true,
    repeatPassword: true,
  });

  /**
   * Navigate to the login
   */
  const navigateToLogin = () => {
    navigate("/login");
  };

  /**
   * Navigates to the therecam page.
   */
  const navigateToTherecam = () => {
    navigate("/therecam");
  };

  /**
   * It checks the email field, then if it's valid, it sends a login request with the email and password.
   * If it's not valid, it shows an error message.
   * If it's valid, it shows a success message and redirects to the therecam page.
   */
  const handleLogin = async () => {
    // check if the fields are valid
    const checkFieldsRegex = checkRegex([
      { field: "email", value: fields.email, regex: "EMAIL" },
    ]);
    // change the fieldsValid state
    setFieldsValid({ ...fieldsValid, ...checkFieldsRegex.result });

    // if the fields are not valid, show an error message
    if (!checkFieldsRegex.isValid) {
      displayMesssage("error", "INVALID_EMAIL");
      return;
    }
    // wait for the response
    const requestResult = await login(fields.email, fields.password);

    // if the response is not ok, show an error message
    if (requestResult.status === "error") {
      displayMesssage(requestResult.status, requestResult.message);
      return;
    }

    // if the response is ok, redirect to the therecam page
    navigateToTherecam();
  };

  /**
   * It checks the validity of the fields, and if they are valid, it sends a request to the server to
   * register the user
   * If the fields are not valid, it shows an error message.
   * If the fields are valid, it shows a success message and redirects to the login page.
   */
  const handleRegister = async () => {
    // check if the fields are valid
    const regexResult = checkRegex([
      { field: "email", value: fields.email, regex: "EMAIL" },
      { field: "password", value: fields.password, regex: "PASSWORD" },
      {
        field: "repeatPassword",
        value: fields.repeatPassword,
        regex: "PASSWORD",
      },
      { field: "firstname", value: fields.firstname, regex: "NAME" },
      { field: "lastname", value: fields.lastname, regex: "NAME" },
    ]);

    // change the fieldsValid state
    setFieldsValid({ ...fieldsValid, ...regexResult.result });

    // if the fields are not valid, show an error message
    if (!regexResult.isValid) {
      displayMesssage("error", "INVALID_FIELDS");
      return;
    }

    // send the register request
    const requestResult = await register(
      fields.email,
      fields.password,
      fields.firstname,
      fields.lastname
    );

    // if the response is not ok, show an error message
    if (requestResult.status === "error") {
      displayMesssage(requestResult.status, requestResult.message);
      return;
    }

    // if the response is ok, show a success message and redirect to the login page
    displayMesssage(requestResult.status, requestResult.message);
    setTimeout(() => {
      navigateToLogin();
    }, 3000);
  };
  /**
   * Change the state of the given field, and set the validity of the field to true
   * @param {string} field
   * @param {event} e - is used to get the value of the input
   */
  const handleFieldChange = (field, e) => {
    setFields({ ...fields, [field]: e.target.value });
    setFieldsValid({ ...fieldsValid, [field]: true });
  };

  /**
   * Display a message in the toast component, the message should be a constant from the MESSAGES constant
   * @param {string} type
   * @param {string} message - the message is a key of the MESSAGES constant
   */
   const displayMesssage = (type, message) => {
    toastRef.current.show({
      severity: type,
      summary: MESSAGES[message],
    });
  };

  /* It checks if the user is logged in. If he is, it redirects him to the therecam page. */
  useEffect(() => {
    const autoLogin = async () => {
      const logged = await isLogged();
      if (logged.data === true) {
        navigateToTherecam();
      }
    };
    autoLogin();
  }, []);

  /**
   * Reset the state of the fields and the fieldsValid state.
   * The email,firstname,lastname fields are not resetted to keep the values
   * when switching between the login and the register page.
   */
  const resetFields = () => {
    // only reset the password/repeatPassword field
    setFields({
      ...fields,
      password: "",
      repeatPassword: "",
    });

    // reset all the fields validities
    setFieldsValid({
      firstname: true,
      lastname: true,
      email: true,
      password: true,
      repeatPassword: true,
    });
  };

  /* Resetting the fields and fieldsValid state when the page changes. */
  useEffect(() => {
    resetFields();
  }, [page]);

  return (
    <>
      <Navbar page={page} />
      <div className="loginContainer">
        <h2>{page === "login" ? "Login" : "Inscription"}</h2>
        <div className="row justify-content-center">
          <div className="col col-lg-5 col-md-6">
            <div className="row justify-content-end">
              {page === "register" && (
                <>
                  <div className="col col-6">
                    <InputText
                      placeholder="Nom"
                      tooltip="Nom"
                      value={fields.lastname}
                      onChange={(e) => handleFieldChange("lastname", e)}
                      className={fieldsValid.lastname ? "" : "p-invalid"}
                    />
                  </div>
                  <div className="col col-6">
                    <InputText
                      placeholder="Prénom"
                      tooltip="Prénom"
                      value={fields.firstname}
                      className={fieldsValid.firstname ? "" : "p-invalid"}
                      onChange={(e) => handleFieldChange("firstname", e)}
                    />
                  </div>
                </>
              )}

              <div className="col col-12">
                <InputText
                  placeholder="E-mail"
                  tooltip="E-mail"
                  value={fields.email}
                  className={fieldsValid.email ? "" : "p-invalid"}
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>

              <div className="col col-12">
                <Password
                  placeholder="Mot de passe"
                  tooltip="Mot de passe"
                  promptLabel="Entrer le mot de passe"
                  weakLabel="Faible"
                  mediumLabel="Moyen"
                  strongLabel="Fort"
                  value={fields.password}
                  className={fieldsValid.password ? "" : "p-invalid"}
                  feedback={page === "register"}
                  toggleMask={true}
                  onChange={(e) => handleFieldChange("password", e)}
                />
              </div>
              {/*Only displays if the current page is the register one */}
              {page === "register" && (
                <div className="col col-12">
                  <Password
                    placeholder="Répéter le mot de passe"
                    tooltip="Répéter le mot de passe"
                    promptLabel="Entrer le mot de passe"
                    weakLabel="Faible"
                    mediumLabel="Moyen"
                    strongLabel="Fort"
                    className={fieldsValid.repeatPassword ? "" : "p-invalid"}
                    value={fields.repeatPassword}
                    feedback={true}
                    toggleMask={true}
                    onChange={(e) => handleFieldChange("repeatPassword", e)}
                  />
                </div>
              )}

              <div className="col-4">
                {page === "login" ? (
                  <Button
                    label="Login"
                    icon="pi pi-user"
                    className="p-button-outlined p-button-sm .login-button"
                    iconPos="right"
                    onClick={handleLogin}
                  />
                ) : (
                  <Button
                    label="Inscription"
                    icon="pi pi-user-plus"
                    className="p-button-outlined p-button-sm .login-button"
                    iconPos="right"
                    onClick={handleRegister}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} position="bottom-right" />
    </>
  );
};

export default Login;
