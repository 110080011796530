/**
 * Handtrack Wrk
 *
 * This file contains all the functions that use the Handtrack library.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// Handtrack library
import * as handTrack from "handtrackjs";

// constants
import { HAND_RELATIVE_POSITION, HAND_CLASSES } from "../constant/constants";

// Parameters for the hand tracking model
const MODEL_PARAMS = {
  flipHorizontal: true,
  outputStride: 16,
  imageScaleFactor: 1,
  maxNumBoxes: 3,
  iouThreshold: 0.2,
  scoreThreshold: 0.6,
  modelType: "ssd320fpnlite",
  modelSize: "medium",
  bboxLineWidth: "2",
  fontSize: 17,
};

/**
 * Load the model used to track the hands
 * @returns object
 */
const loadModel = async () => {
  const loadedModel = await handTrack.load(MODEL_PARAMS);
  if (loadedModel === null || loadModel === undefined) {
    return { status: false, message: "ERROR_WHILE_LOADING_MODEL" };
  }
  return { status: true, message: "", model: loadedModel };
};

/**
 * Start the video
 * @param {object} video
 * @returns object
 */
const startVideo = async (video) => {
  const result = await handTrack.startVideo(video);
  if (!result.status) {
    return { status: false, message: "NO_WEBCAM_FOUND" };
  }
  return { status: true, message: "" };
};

/**
 * Stop the video
 * @returns object
 */
const stopVideo = async () => {
  const result = await handTrack.stopVideo();
  if(!result){
    return  { status: true, message: "" };
  }
  if (!result.status) {
    return { status: false, message: "ERROR_WHILE_STOPPING_VIDEO" };
  }
  return { status: true, message: "" };
};

/**
 * Track the hand position, render the hands and return the relative positions
 * @param {object} model
 * @param {object} canvas
 * @param {object} context
 * @param {object} video
 * @returns
 */
const trackHands = async (model, canvas, context, video) => {
  const currentPredictions = await model.detect(video);
  let hands = findHands(currentPredictions, canvas);
  if(hands !== -1){
    hands = scaleHandsPositionsToRelativePositions(hands, canvas);
  }
  model.renderPredictions(currentPredictions, canvas, context, video);
  return hands;
};

/**
 * Find the left and right hands in the predictions
 * @param {array} predictions
 * @returns object
 */
const findHands = (predictions, canvas) => {
  const { width } = canvas;
  const handsPositions = {
    left: -1,
    right: -1,
  };

  predictions = predictions
    .filter((prediction) => {
      if (HAND_CLASSES.includes(prediction.class)) {
        return prediction.bbox;
      }
    })
    .map((prediction) => {
      return prediction.bbox;
    });
  if (predictions.length === 0) return-1;

  if (predictions.length === 1 && predictions[0][0] < width / 2) {
    handsPositions.left = predictions[0];
    return handsPositions;
  }

  if (predictions.length === 1 && predictions[0][0] > width / 2) {
    handsPositions.right = predictions[0];
    return handsPositions;
  }

  handsPositions.left =
    predictions[0][0] < predictions[1][0] ? predictions[0] : predictions[1];
  handsPositions.right =
    predictions[0][0] < predictions[1][0] ? predictions[1] : predictions[0];
  return handsPositions;
};

/**
 * Convert the position of the hand to a relative position
 * @param {array} hands
 * @param {object} canvas
 * @returns
 */
const scaleHandsPositionsToRelativePositions = (hands, canvas) => {
  const { width, height } = canvas;
  let relativeHandsPositions = {
    left: -1,
    right: -1,
  };
  if(hands.left !== -1){
    relativeHandsPositions.left = {
      x: scaleHandX(hands.left, width),
      y: scaleHandY(hands.left, height),
    };
  }
  if(hands.right  !== -1){
    relativeHandsPositions.right = {
      x: scaleHandX(hands.right, width),
      y: scaleHandY(hands.right, height),
    };
  }

  return relativeHandsPositions;
};

/**
 * Calculate the x position of the hand relative to the canvas
 * @param {array} handPosition
 * @param {int} canvasWidth
 * @returns int
 */
const scaleHandX = (handPosition, canvasWidth) => {
  return Math.round(
    ((handPosition[0] + handPosition[2] / 2) / canvasWidth) *
      HAND_RELATIVE_POSITION.MAX
  );
};

/**
 * Calculate the y position of the hand relative to the canvas
 * @param {array} handPosition
 * @param {int} canvasWidth
 * @returns int
 */
const scaleHandY = (handPosition, canvasHeight) => {
  return (
    HAND_RELATIVE_POSITION.MAX -
    Math.round(
      ((handPosition[1] + handPosition[3] / 2) / canvasHeight) *
        HAND_RELATIVE_POSITION.MAX
    )
  );
};

export { loadModel, startVideo, stopVideo, trackHands };
