/**
 * Regex utils
 *
 * This file contains utils to work with regex.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// Regex expressions constant
import { REGEX } from "../constant/constants";

/**
 * It takes in an object of values and an array of regex expressions and returns an object with a
 * boolean value and an object of the validity of each value
 * @param values - The values to be checked.
 * @param regexExpressions - This is the key of the REGEX object.
 * @returns An object with two keys, isValid and result.
 */
const checkRegex = (values) => {
  let isValid = true;
  const result = {};
  values.forEach((value) => {
    const regex = new RegExp(REGEX[value.regex]);
    const regexValidity = regex.test(value.value);
    result[value.field] = regexValidity;
    if (isValid) {
      isValid = regexValidity;
    }
  });
  return { isValid: isValid, result: result };
};

export { checkRegex };
