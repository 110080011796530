/**
 * Login request worker
 *
 * This file contains the functions that make API
 * requests to the login API endpoint.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */


// ApiWrk functions
import { get, post } from "../wrk/apiWrk";

// Api endpoints
import { ENDPOINTS } from "../constant/constants";

/**
 * Create a new account using the given information
 * @param {string} email 
 * @param {string} password
 * @param {string} firstname
 * @param {string} lastname 
 * @returns Promise
 */
const register = async (email, password, firstname, lastname) => {
  return await post(ENDPOINTS.LOGIN, "register", {
    email,
    password,
    firstname,
    lastname,
  });
};

/**
 * Log the user in with the given credentials
 * @param {string} email 
 * @param {string} password 
 * @returns Promise
 */
const login = async (email, password) => {
  return await post(ENDPOINTS.LOGIN, "login", {
    email,
    password,
  });
};

/**
 * Check if the user is logged in
 * @returns Promise
 */
const isLogged = async () => {
  return await get(ENDPOINTS.LOGIN, "isLogged");
};

/**
 * Log the current user out
 * @returns Promise
 */
const logout = async () => {
  return await get(ENDPOINTS.LOGIN, "logout");
};

export { register, login, isLogged, logout };
