/**
 * Navbar Data
 *
 * This file contains the links that are displayed in the navbar.
 *
 * JavaScript Module
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */
const navbarData = [
  {
    label: "Accueil",
    path: "/",
    icon: "pi pi-home",
  },
  {
    label: "Login",
    path: "/login",
    icon: "pi pi-user",
  },
  {
    label: "Inscription",
    path: "/register",
    icon: "pi pi-user-plus",
  },
];

export { navbarData };
