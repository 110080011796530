/**
 * Therecam page
 *
 * This file contains all the state logic and display logic for the Therecam page.
 * Displays all the user compositions and permit to record new ones.
 *
 * React component
 *
 * @author Elwan Mayencourt
 * @version 1.0
 */

// react
import { useState, useEffect, useRef } from "react";

// PrimeReact components
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressBar } from "primereact/progressbar";
import { Image } from "primereact/image";

// PrimeReact functions
import { confirmDialog } from "primereact/confirmdialog";

// custom components
import Navbar from "../components/Navbar";
import CompositionsList from "../components/CompositionsList";

// custom css
import "../css/therecam.css";

// navigation
import { useNavigate } from "react-router-dom";

// controllers
import {
  getUserCompositions,
  updateComposition,
  deleteComposition,
  insertComposition,
  loadModel,
  startVideo,
  stopVideo,
  trackHands,
  startOscillator,
  stopOscillator,
  changeOscillatorNote,
  playAudio,
  stopAudio,
} from "../ctrl/compositionCtrl";
import {
  updateUserName,
  updateUserPassword,
  getUserName,
} from "../ctrl/userCtrl";
import { logout } from "../ctrl/loginCtrl";

// constants
import { MESSAGES, SAMPLE_INTERVAL } from "../constant/constants";

// utils
import { checkRegex } from "../utils/regexUtils";

// images
import webCamNotFound from "../assets/webcamNotFound.png";
import { getCompositionAudio } from "../api/compositionApi";

const Therecam = () => {
  // navigation
  const navigate = useNavigate();

  // states
  const [activeTabIndex, setActiveTabIndex] = useState(0); // track the active tabview page index
  const [isEditing, setIsEditing] = useState(false); // store if a composition is being edited or not
  const [displayProfileDialog, setDisplayProfileDialog] = useState(false); // store if the profile dialog is displayed
  const [userCompositions, setUserCompositions] = useState([]); // store the user compositions
  const [compositionBeingEdited, setCompositionBeingEdited] = useState(null); // store the current composition being edited
  const [compositionFields, setCompositionFields] = useState({
    newTitle: "",
    updateTitle: "",
  }); // store the composition fields
  const [compositionFieldsValid, setCompositionFieldsValid] = useState({
    newTitle: true,
    updateTitle: true,
    numberOfPlays: 0,
  }); // store if the composition fields are valid or not
  const [profileFields, setProfileFields] = useState({
    firstname: "",
    lastname: "",
    newPassword: "",
    currentPassword: "",
  }); // store the profile fields
  const [profileFieldsValid, setProfileFieldsValid] = useState({
    firstname: true,
    lastname: true,
    newPassword: true,
    currentPassword: true,
  }); // store if the profile fields are valid or not
  const [model, setModel] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(null); // store the interval that allows to retrieve the samples
  const [isRecording, setIsRecording] = useState(false); // store if the user is recording or not
  const [isWebcamAvailable, setIsWebcamAvailable] = useState(true); // store if the webcam is available or not
  const [isWebcamLoading, setIsWebcamLoading] = useState(false); // store if the webcam is loading or not
  const [isModelLoading, setIsModelLoading] = useState(false); // store if the model is loading or not

  // refs
  const toastRef = useRef(null);
  const videoRef = useRef(null); // Store the video element
  const canvasRef = useRef(null); // Store the canvas element
  const currentPosition = useRef(null); // Store the current position of the hands
  const noHandsDetectedCounter = useRef(0); // Store the number of times no hands were detected
  const isRecordingRef = useRef(false); // Store the isRecording state
  const samplesRef = useRef([]); // Store the samples
  const audioRef = useRef(null); // Store the audio file
  const compositionCurrentlyPlaying = useRef({
    pk_composition: null,
    audio: null,
  }); // Store the composition currently playing

  // events

  const record = async () => {
    // reset the refs
    isRecordingRef.current = true;
    samplesRef.current = [];
    audioRef.current = null;

    setIsWebcamLoading(true);

    // start the video
    const resultStartVideo = await startVideo(videoRef.current);
    // start the oscillator
    const resultStartOscillator = await startOscillator();

    setIsWebcamLoading(false);

    // set the state of the webcam availability
    setIsWebcamAvailable(
      resultStartVideo.status && resultStartOscillator.status
    );

    // display error message if the video or the oscillator failed to start
    if (!resultStartVideo.status) {
      displayMesssage("error", resultStartVideo.message);
      isRecordingRef.current = false;
      setIsRecording(false);
      return;
    }

    if (!resultStartOscillator.status) {
      displayMesssage("error", resultStartOscillator.message);
      isRecordingRef.current = false;
      setIsRecording(false);
      return;
    }

    // start the loop used to track the hands
    startTrackHandsLoop();
  };

  const stopRecording = async () => {
    isRecordingRef.current = false;
    setIsWebcamAvailable(true);

    // stop the oscillator
    const resultStopVideo = await stopVideo();
    const resultStopOscillator = await stopOscillator();
    // clear the running interval
    clearInterval(refreshInterval);

    // display error message if the video or the oscillator failed to stop
    if (!resultStopVideo.status) {
      displayMesssage("error", resultStopVideo.message);
    }

    if ("status" in resultStopOscillator && !resultStopOscillator.status) {
      displayMesssage("error", resultStopOscillator.message);
    }

    audioRef.current = resultStopOscillator;
  };

  const startTrackHandsLoop = async () => {
    // get the canvas context
    const canvasContext = canvasRef.current.getContext("2d");
    // clear the current interval
    clearInterval(refreshInterval);
    // start the oscillator
    startOscillator();

    // create the interval that will be used to play the audio and save the samples
    const newInterval = setInterval(() => {
      if (!currentPosition || currentPosition.current === -1) {
        samplesRef.current.push({ positions: -1, frequency: -1, volume: -1 });
        return;
      }

      // change the frequency and the volume  of the oscillator based on the current position
      const frequencyAndVolume = changeOscillatorNote(
        currentPosition.current.right.x,
        currentPosition.current.left.y
      );

      // add sample to the samples array
      samplesRef.current.push({
        positions: { ...currentPosition.current },
        ...frequencyAndVolume,
      });
    }, SAMPLE_INTERVAL);

    // set the interval
    setRefreshInterval(newInterval);

    // start the handtracking loop
    while (isRecordingRef.current && isWebcamAvailable) {
      // get the current position of the hands
      const handsPos = await trackHands(
        model,
        canvasRef.current,
        canvasContext,
        videoRef.current
      );
      // set the current position of the hands
      if (handsPos !== -1) {
        currentPosition.current = handsPos;
        continue;
      }

      noHandsDetectedCounter.current++;
      if (noHandsDetectedCounter.current >= 20) {
        noHandsDetectedCounter.current = 0;
        currentPosition.current = -1;
      }
    }
  };

  /**
   * Load the Hand Tracking model
   */
  const loadModelAsync = async () => {
    setIsModelLoading(true);
    const model = await loadModel();
    setIsModelLoading(false);
    if (!model.status) {
      displayMesssage("error", model.message);
      return;
    }
    setModel(model.model);
  };

  /**
   * Display a confirm dialog with a custom message.
   * @param {string} message - The message to display in the confirm dialog.
   * @param {function} callback - The callback to execute when the confirm dialog is confirmed.
   */
  const confirm = (text, accept) => {
    confirmDialog({
      message: text,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Oui",
      rejectLabel: "Non",
      accept: accept,
    });
  };

  // events handlers
  const onProfileClick = () => {
    setDisplayProfileDialog(true);
  };

  // hide the profile dialog
  const onProfileDialogHide = () => {
    setDisplayProfileDialog(false);
  };

  // play the given composition
  const onCompositionPlay = async (composition, isPlaying) => {
    if (!isPlaying) {
      // stop the currently playing audio, no need to give the back the audio file
      playAudio("", false, false);
      return;
    }

    // get the audio file encoded in base64
    if (
      compositionCurrentlyPlaying.current.pk_composition !==
      composition.pk_composition
    ) {
      const requestResult = await getCompositionAudio(
        composition.pk_composition
      );
      // display an error message if the request failed
      if (requestResult.status === "error") {
        handleRequestError(requestResult.message);
        return;
      }

      compositionCurrentlyPlaying.current = {
        pk_composition: composition.pk_composition,
        audio: requestResult.data,
        numberOfPlays: 0,
      };
    }

    compositionCurrentlyPlaying.current.numberOfPlays++;

    // play the audio file
    const audioPlayingStatus = await playAudio(
      compositionCurrentlyPlaying.current.audio,
      true,
      compositionCurrentlyPlaying.current.numberOfPlays === 1
    );

    if (!audioPlayingStatus.status) {
      displayMesssage("error", audioPlayingStatus.message);
    }
  };

  // stop playing the given composition, the compositon is returned by the event but not used
  const onCompositionStop = (composition) => {
    compositionCurrentlyPlaying.current.numberOfPlays = 0;
    stopAudio();
  };

  // edit the given composition
  const onCompositionEdit = (composition, isEditing) => {
    setIsEditing(isEditing);
    if (isEditing) {
      setCompositionBeingEdited(composition.pk_composition);
      setCompositionFields({
        ...compositionFields,
        updateTitle: composition.title,
      });

      return;
    }
    setCompositionBeingEdited(null);
  };

  /**
   * Display a dialog to confirm the deletion of a composition.
   * @param {object} composition
   */
  const onCompositionDelete = (composition) => {
    confirm(`Voulez-vous vraiment supprimer <${composition.title}> ?`, () =>
      handleCompositionDelete(composition.pk_composition)
    );
  };

  /**
   * Handle the deletion of a composition.
   * @param {int} pk_composition
   */
  const handleCompositionDelete = async (pk_composition) => {
    const requestResult = await deleteComposition(pk_composition);

    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }
    displayMesssage(requestResult.status, requestResult.message);
    // if the composition was deleted, remove it from the list
    setUserCompositions(
      userCompositions.filter(
        (composition) => composition.pk_composition !== pk_composition
      )
    );
  };

  /**
   * Display a message in the toast component, the message should be a constant from the MESSAGES constant
   * @param {string} type
   * @param {string} message - the message is a key of the MESSAGES constant
   */
  const displayMesssage = (type, message) => {
    toastRef.current.show({
      severity: type,
      summary: MESSAGES[message],
    });
  };

  const handleUpdateUserName = async () => {
    // check if the user firstname and lastname are valid
    const checkFieldsRegex = checkRegex([
      { field: "firstname", value: profileFields.firstname, regex: "NAME" },
      { field: "lastname", value: profileFields.lastname, regex: "NAME" },
    ]);

    // if the fields are not valid change the state
    if (!checkFieldsRegex.isValid) {
      setProfileFieldsValid({
        ...profileFieldsValid,
        ...checkFieldsRegex.result,
      });
      return;
    }

    // if the fields are valid, update the user name
    const requestResult = await updateUserName(
      profileFields.firstname,
      profileFields.lastname
    );

    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }
    // display the message corresponding to the request result
    displayMesssage(requestResult.status, requestResult.message);
  };

  const handleUpdateUserPassword = async () => {
    // check if the user current password and new password are valid
    const checkFieldsRegex = checkRegex([
      {
        field: "newPassword",
        value: profileFields.newPassword,
        regex: "PASSWORD",
      },
      {
        field: "currentPassword",
        value: profileFields.currentPassword,
        regex: "PASSWORD",
      },
    ]);

    // if the fields are not valid change the state
    if (!checkFieldsRegex.isValid) {
      setProfileFieldsValid({
        ...profileFieldsValid,
        ...checkFieldsRegex.result,
      });
      return;
    }

    // if the fields are valid, update the user password
    const requestResult = await updateUserPassword(
      profileFields.newPassword,
      profileFields.currentPassword
    );

    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }

    // display the message corresponding to the request result
    displayMesssage(requestResult.status, requestResult.message);
  };

  const handleLogout = async () => {
    //wait for the logout request to finish
    await logout();
    //navigate to the login page
    navigate("/");
  };

  /**
   * Change the state of the given field, and set the validity of the field to true
   * @param {string} field
   * @param {event} e - is used to get the value of the field
   */
  const handleProfileFieldsChange = (field, e) => {
    setProfileFields({ ...profileFields, [field]: e.target.value });
    setProfileFieldsValid({ ...profileFieldsValid, [field]: true });
  };

  /**
   * Change the state of the given field, and set the validity of the field to true
   * @param {string} field
   * @param {event} e - is used to get the value of the field
   */
  const handleCompositionFieldsChange = (field, e) => {
    setCompositionFields({ ...compositionFields, [field]: e.target.value });
    setCompositionFieldsValid({ ...compositionFieldsValid, [field]: true });
  };

  /**
   * Update the composition title
   */
  const handleUpdateCompositionTitle = async () => {
    // check if the composition title is valid
    const checkFieldsRegex = checkRegex([
      {
        field: "updateTitle",
        value: compositionFields.updateTitle,
        regex: "COMPOSITION_TITLE",
      },
    ]);

    // if not valid, set the composition fields valid state
    if (!checkFieldsRegex.isValid) {
      setCompositionFieldsValid({
        ...compositionFieldsValid,
        ...checkFieldsRegex.result,
      });
      return;
    }

    // if valid, send request to update the composition title
    const requestResult = await updateComposition(
      compositionBeingEdited,
      compositionFields.updateTitle
    );

    // if the request was unsuccessful, display the error message
    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }

    // if the request was successful, update the user compositions
    setCompositionFields({
      ...compositionFields,
      updateTitle: compositionFields.updateTitle,
    });

    setUserCompositions(
      // filter the user compositions to update the one with the updated title
      userCompositions.map((composition) => {
        if (composition.pk_composition === compositionBeingEdited) {
          return {
            ...composition,
            title: compositionFields.updateTitle,
          };
        }
        return composition;
      })
    );

    // display the success message
    displayMesssage(requestResult.status, requestResult.message);
  };

  /**
   * Handle the creation of a new composition
   */
  const handleSaveComposition = async () => {
    const regexValid = checkRegex([
      {
        field: "newTitle",
        value: compositionFields.newTitle,
        regex: "COMPOSITION_TITLE",
      },
    ]);

    // set the composition fields valid state
    setCompositionFieldsValid({
      ...compositionFieldsValid,
      ...regexValid.result,
    });
    if (!regexValid.isValid) {
      return;
    }

    // get the request result
    const requestResult = await insertComposition(
      audioRef.current,
      SAMPLE_INTERVAL,
      samplesRef.current,
      compositionFields.newTitle
    );

    // clear the composition fields
    audioRef.current = null;
    samplesRef.current = null;
    setCompositionFields({
      ...compositionFields,
      newTitle: "",
    });

    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }

    displayMesssage(requestResult.status, requestResult.message);
  };

  /**
   * Load all the compositions belonging to the current user.
   */
  const loadUserCompositions = async () => {
    const requestResult = await getUserCompositions();
    if (requestResult.status === "error") {
      handleRequestError(requestResult.message);
      return;
    }
    setUserCompositions(requestResult.data);
  };

  /**
   * Load the user from the localStorage and set the user's data.
   */
  const loadUserData = () => {
    const username = getUserName();
    setProfileFields({
      ...profileFields,
      firstname: username.firstname,
      lastname: username.lastname,
    });
  };

  /**
   * Hand
   * @param {string} message
   */
  const handleRequestError = (message) => {
    displayMesssage("error", message);
    if (message === "FORBIDDEN") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  };

  // load the model only if the second tab is open, if the tab is switched to the first tab, the record automatically stops
  useEffect(() => {
    if (activeTabIndex == 1) {
      loadModelAsync();
    }
    if (activeTabIndex == 0) {
      loadUserCompositions();
      loadUserData();

      isRecordingRef.current = false;
      setIsRecording(false);
    }
  }, [activeTabIndex]);

  // Starts the recording when the state is set to true, and stops when is set to false
  useEffect(() => {
    if (isRecording) {
      record();
    } else {
      stopRecording();
    }
  }, [isRecording]);

  return (
    <div className="homeContainer">
      <Navbar page="therecam" onProfileClick={onProfileClick} />
      <div className="row justify-content-center">
        <div className="col-8 ">
          <Card>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => setActiveTabIndex(e.index)}
            >
              <TabPanel header="Mes oeuvres">
                <CompositionsList
                  isEditable={true}
                  compositions={userCompositions}
                  onPlay={onCompositionPlay}
                  onStop={onCompositionStop}
                  onEdit={onCompositionEdit}
                  onDelete={onCompositionDelete}
                />
                <div className="row mt-5 justify-content-end">
                  {isEditing && (
                    <div className="col col-6">
                      <InputText
                        placeholder="Titre"
                        className={`p-inputtext-sm mx-2 ${
                          compositionFieldsValid.updateTitle ? "" : "p-invalid"
                        }`}
                        value={compositionFields.updateTitle}
                        onChange={(e) =>
                          handleCompositionFieldsChange("updateTitle", e)
                        }
                      ></InputText>
                      <Button
                        label="Enregistrer"
                        className="p-button-sm p-button-outlined"
                        onClick={handleUpdateCompositionTitle}
                      />
                    </div>
                  )}
                  <div className="col col-6 text-end ">
                    <Button
                      label="Créer une nouvelle oeuvre"
                      className="p-button-sm p-button-outlined"
                      onClick={() => setActiveTabIndex(1)}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Jouer">
                <div className="row justify-content-center gy-3">
                  <div className="col col-6">
                    <label className="mx-1">Titre : </label>
                    <InputText
                      placeholder="Titre"
                      className={`p-inputtext-sm me-2 ${
                        compositionFieldsValid.newTitle ? "" : "p-invalid"
                      }`}
                      value={compositionFields.newTitle}
                      onChange={(e) =>
                        handleCompositionFieldsChange("newTitle", e)
                      }
                    />
                    <Button
                      icon={isRecording ? "pi pi-circle-fill" : "pi pi-circle"}
                      className={`p-button-sm p-button-outlined mx-2 ${
                        isRecording ? "recordingComposition" : ""
                      }`}
                      tooltip={
                        isRecording
                          ? "Stopper l'enregistrement"
                          : "Commencer l'enregistrement"
                      }
                      disabled={isModelLoading}
                      onClick={() => setIsRecording(!isRecording)}
                    />
                  </div>

                  <div className="col col-12 text-center">
                    {isWebcamLoading && (
                      <ProgressBar mode="indeterminate"></ProgressBar>
                    )}
                    <video
                      ref={videoRef}
                      autoPlay
                      height="200px"
                      style={{ display: "none" }}
                    ></video>
                    <canvas
                      ref={canvasRef}
                      style={{
                        display: isRecording && !isWebcamLoading ? "" : "none",
                      }}
                    ></canvas>
                    <img
                      src={webCamNotFound}
                      style={{
                        maxWidth: "100%",
                        display: !isWebcamAvailable ? "" : "none",
                      }}
                    ></img>
                  </div>
                  <div className="col col-8 text-end">
                    <Button
                      label="Sauver"
                      className="p-button-sm p-button-outlined"
                      onClick={handleSaveComposition}
                      disabled={isRecording || isModelLoading}
                    />
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Card>
        </div>
      </div>
      <Dialog
        header="Profil"
        visible={displayProfileDialog}
        style={{ width: "30vw" }}
        onHide={() => onProfileDialogHide()}
        closeOnEscape={true}
        dismissableMask={true}
        className="profileDialog"
      >
        <div className="row justify-content-end gx-3 gy-1 pt-2">
          <div className="col col-6">
            <InputText
              placeholder="Nom"
              className={profileFieldsValid.lastname ? "" : "p-invalid"}
              value={profileFields.lastname}
              onChange={(e) => handleProfileFieldsChange("lastname", e)}
            />
          </div>
          <div className="col col-6">
            <InputText
              placeholder="Prénom"
              className={profileFieldsValid.firstname ? "" : "p-invalid"}
              value={profileFields.firstname}
              onChange={(e) => handleProfileFieldsChange("firstname", e)}
            />
          </div>
          <div className="col col-lg-5 text-end mb-3">
            <Button
              label="Enregistrer"
              className="p-button-outlined p-button-sm"
              onClick={handleUpdateUserName}
            />
          </div>
          <div className="col col-12"></div>

          <div className="col col-6">
            <Password
              placeholder="Nouveau mot de passe"
              promptLabel="Entrer un nouveau mot de passe"
              weakLabel="Faible"
              mediumLabel="Moyen"
              strongLabel="Fort"
              value={profileFields.newPassword}
              className={profileFieldsValid.newPassword ? "" : "p-invalid"}
              feedback={true}
              toggleMask={true}
              onChange={(e) => handleProfileFieldsChange("newPassword", e)}
            />
          </div>
          <div className="col col-6">
            <Password
              placeholder="Mot de passe actuel"
              tooltip="Mot de passe actuel"
              value={profileFields.currentPassword}
              className={profileFieldsValid.currentPassword ? "" : "p-invalid"}
              feedback={false}
              toggleMask={true}
              onChange={(e) => handleProfileFieldsChange("currentPassword", e)}
            />
          </div>
          <div className="col col-5 mb-5">
            <Button
              label="Enregistrer"
              className="p-button-outlined p-button-sm"
              onClick={handleUpdateUserPassword}
            />
          </div>
          <div className="col col-12">
            <Button
              label="Se déconnecter"
              icon="pi pi-sign-out"
              className="p-button-danger p-button-outlined p-button-sm"
              iconPos="right"
              onClick={() => handleLogout()}
            />
          </div>
        </div>
      </Dialog>
      <ConfirmDialog />
      <Toast ref={toastRef} position="bottom-right" />
    </div>
  );
};

export default Therecam;
